<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Relatório de tickets </hero-bar>
    <section class="section is-main-section">
      <div class="row columns is-multiline is-vcentered">
        <div class="column is-one-quarter">
          <b-field label="Número de origem" label-position="on-border">
            <multiselect v-model="phoneFilter" :multiple="true" :options="getPhones" label="name" track-by="id"
              select-label="" deselect-label="">
              <template slot="placeholder">Número de origem</template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Atendentes" label-position="on-border">
            <multiselect v-model="attendantsFilter" :multiple="true" :options="getAttendants" label="name" track-by="id"
              select-label="" deselect-label="" :disabled="afterHoursFilter">
              <template slot="placeholder">Atendentes</template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Contatos" label-position="on-border">
            <multiselect v-model="contactsFilter" :multiple="true" :options="getContacts" label="name" track-by="id"
              select-label="" deselect-label="">
              <template slot="placeholder">Contatos</template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Departamentos" label-position="on-border">
            <multiselect v-model="departmentsFilter" :multiple="true" :options="getDepartments" label="name" track-by="id"
              select-label="" deselect-label="">
              <template slot="placeholder">Departamentos</template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Nome/Tel. do cliente" label-position="on-border">
            <b-input expanded type="search" v-model="namePhoneFilter" placeholder="Nome ou telefone do cliente"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Status" label-position="on-border">
            <b-select expanded v-model="statusFilter" @click.native="statusChange()" :disabled="afterHoursFilter">
              <option v-for="status in ticketStatus" :value="status.value" :key="status.value">
                {{ status.text }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Tipo do ticket" label-position="on-border">
            <b-select expanded v-model="typeFilter" :disabled="afterHoursFilter">
              <option v-for="status in ticketTypes" :value="status.value" :key="status.value">
                {{ status.text }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Motivo do fechamento" label-position="on-border">
            <b-select expanded v-model="reasonFilter" :disabled="disabledReasonFilter || afterHoursFilter">
              <option v-for="reason in allReasons" :value="reason.id" :key="reason.id">
                {{ reason.title }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Criado de" label-position="on-border">
            <b-input expanded type="date" v-model="dateFromFilter" placeholder="Nome ou telefone do cliente"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Criado até" label-position="on-border">
            <b-input expanded type="date" v-model="dateToFilter" placeholder="Nome ou telefone do cliente"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Fechado de" label-position="on-border">
            <b-input :disabled="disabledReasonFilter" expanded type="date" v-model="closedDateFromFilter"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Fechado até" label-position="on-border">
            <b-input :disabled="disabledReasonFilter" expanded type="date" v-model="closedDateToFilter"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field type="is-warning" label="ID do ticket (ignora outros filtros)" label-position="on-border">
            <b-input expanded type="search" v-model="ticketID" placeholder="ID do ticket"
              @keydown.native.enter="applyTicketsFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <b-field label="Origem" label-position="on-border">
            <b-select expanded v-model="ticketOriginFilter">
              <option value=""></option>
              <option v-for="origin in ticketOrigins" :value="origin.value" :key="origin.value">
                {{ origin.text }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-quarter">
          <div class="field">
            <b-checkbox v-model="withoutDepartmentFilter">Apenas tickets sem departamento</b-checkbox>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="column is-one-quarter">
            <div class="field">
              <b-checkbox v-model="afterHoursFilter">Apenas tickets fora do horário de funcionamento</b-checkbox>
            </div>
          </div>
        </div>
        <div class="column is-one-sixth">
          <p class="control">
            <b-button icon-right="download" class="button is-info is-fullwidth" @click="exportData()"
              :loading="isExporting">
              Exportar
            </b-button>
          </p>
        </div>
        <div class="column is-one-sixth">
          <p class="control">
            <button class="button is-primary is-fullwidth" @click="applyTicketsFilter()">
              Filtrar
            </button>
          </p>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="fetchingDashboard" :can-cancel="false"></b-loading>
      <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
      <card-component title="Tickets" class="has-table has-mobile-sort-spaced overflow-x-auto">
        <b-table :data="ticketsReport" :bordered="true" :striped="true" paginated :mobile-cards="true"
          sort-icon="arrow-up" sort-icon-size="is-small" default-sort="id" aria-next-label="Próxima página"
          aria-previous-label="Página anterior" aria-page-label="Página" aria-current-label="Página atual" scrollable
          :show-detail-icon="true" detailed>
          <b-table-column sortable field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="phone" label="Número de origem" v-slot="props">
            {{ getPhone(props.row.setting) }}
          </b-table-column>

          <b-table-column field="client_name" label="Nome no whats" v-slot="props">
            {{ props.row.client_name }}
          </b-table-column>

          <b-table-column field="contact.name" label="Contato" v-slot="props">
            {{
              props.row.contact
              ? `${props.row.contact.id} - ${props.row.contact.name}`
              : ""
            }}
          </b-table-column>

          <b-table-column field="contact.name" label="Departamento" v-slot="props">
            {{ props.row.department ? `${props.row.department.id} - ${props.row.department.name}` : '' }}
          </b-table-column>

          <b-table-column field="client_phone" label="Telefone" v-slot="props">
            {{ props.row.is_group ? "" : props.row.client_phone }}
          </b-table-column>

          <b-table-column sortable field="client_phone" label="Tipo" v-slot="props">
            <is-group-tag :is_group="props.row.is_group" />
          </b-table-column>

          <b-table-column sortable field="client_phone" label="Origem" v-slot="props">
            <TicketOriginTag :origin="props.row.origin" />
          </b-table-column>

          <b-table-column field="attendant_name" label="Atendente" v-slot="props">
            {{ props.row.attendant ? props.row.attendant.name : "" }}
          </b-table-column>

          <b-table-column sortable field="is_open" label="Status" v-slot="props">
            {{ props.row.is_open ? "Aberto" : "Fechado" }}
          </b-table-column>

          <b-table-column field="created_at" label="Data de abertura" v-slot="props">
            {{ formatDateTime(props.row.created_at) }}
          </b-table-column>

          <b-table-column field="closed_at" label="Data de fechamento" v-slot="props">
            {{ props.row.closed_at ? formatDateTime(props.row.closed_at) : null }}
          </b-table-column>

          <b-table-column field="actions" label="Mensagens" v-slot="props">
            <MessagesTicketButton :ticket="props.row" />
          </b-table-column>

          <b-table-column field="actions" label="Transferir" v-slot="props" v-if="getIsSupervisor">
            <TransferTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <b-table-column field="actions" label="Fechar" v-slot="props" v-if="getIsSupervisor">
            <CloseTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ ticketsReport.length }}
            </div>
          </template>

          <template #detail="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p v-if="props.row.ticket_reason">
                    Motivo do fechamento: <strong>{{ props.row.ticket_reason.id }} - {{ props.row.ticket_reason.title
                    }}</strong>
                    <br />
                  </p>
                  <p v-if="props.row.ticket_reason_explanation">
                    Explicação do atendente: <br />
                    <strong>{{ props.row.ticket_reason_explanation }}</strong>
                  </p>
                </div>
              </div>
            </article>
          </template>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapGetters } from 'vuex'
import { addDays } from 'date-fns'
import CloseTicketButton from '@/components/tickets/CloseTicketButton'
import TransferTicketButton from '@/components/tickets/TransferTicketButton'
import MessagesTicketButton from '@/components/tickets/MessagesTicketButton'
import IsGroupTag from '@/components/commons/IsGroupTag.vue'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import multiselect from 'vue-multiselect'
import mixin from '../utils/mixins'
import exportExcel from '../exportExcel'
import TicketOriginTag from '@/components/commons/TicketOriginTag.vue'

export default {
  name: 'TicketsReport',
  components: {
    CardComponent,
    HeroBar,
    TitleBar,
    multiselect,
    CloseTicketButton,
    TransferTicketButton,
    MessagesTicketButton,
    IsGroupTag,
    TicketOriginTag
  },
  mixins: [mixin],
  data() {
    return {
      ticketID: '',
      phoneFilter: '',
      namePhoneFilter: '',
      attendantsFilter: [],
      contactsFilter: [],
      dateFromFilter: '',
      dateToFilter: '',
      closedDateFromFilter: '',
      closedDateToFilter: '',
      departmentsFilter: [],
      reasonFilter: '',
      disabledReasonFilter: true,
      statusFilter: 'ABE',
      typeFilter: 'TOD',
      withoutDepartmentFilter: false,
      afterHoursFilter: false,
      ticketOriginFilter: '',
      ticketTypes: [
        {
          value: 'TOD',
          text: 'Grupos e contatos'
        },
        {
          value: 'GRP',
          text: 'Somente grupos'
        },
        {
          value: 'USR',
          text: 'Somente contatos'
        }
      ],
      ticketOrigins: [
        {
          value: 'CLI',
          text: 'Cliente'
        },
        {
          value: 'ATE',
          text: 'Atendente'
        },
        {
          value: 'INT',
          text: 'Integração'
        }
      ],
      ticketStatus: [
        {
          value: 'TOD',
          text: 'Todos'
        },
        {
          value: 'ABE',
          text: 'Abertos'
        },
        {
          value: 'ABS',
          text: 'Abertos sem atendente'
        },
        {
          value: 'ABA',
          text: 'Abertos com atendente'
        },
        {
          value: 'FEC',
          text: 'Fechados'
        }
      ],
      selectedTicket: null,
      loading: false,
      phones: [],
      isExporting: false,
      userDepartments: []
    }
  },
  computed: {
    ...mapGetters([
      'ticketsReport',
      'fetchingDashboard',
      'allAttendants',
      'allContacts',
      'allDepartments',
      'allReasons',
      'getIsSupervisor',
      'getIsSupervisorAllDepartments',
      'allSettings',
      'getUserDepartments',
      'getToken'
    ]),
    titleStack() {
      return ['Admin', 'Relatório de tickets']
    },
    getAttendants() {
      return this.allAttendants.map((attendant) => {
        const c = attendant
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    },
    getContacts() {
      return this.allContacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    },
    getDepartments() {
      return this.userDepartments.map((department) => {
        const c = department
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    },
    getPhones() {
      return this.phones.map((setting, index) => {
        const c = setting
        c.code = index + 1
        c.id = index++
        let phone = ''
        if (setting.name) {
          phone = phone + setting.name + '/'
        }
        if (setting.phone) {
          phone = phone + setting.phone
        }
        c.name = phone
        return c
      })
    }
  },
  async mounted() {
    this.dateFromFilter = addDays(new Date(), -5).toISOString().slice(0, 10)
    this.dateToFilter = new Date().toISOString().slice(0, 10)
    this.closedDateFromFilter = this.disabledReasonFilter
      ? null
      : addDays(new Date(), -5).toISOString().slice(0, 10)
    this.closedDateToFilter = this.disabledReasonFilter
      ? null
      : new Date().toISOString().slice(0, 10)
    this.fetchContacts()
    this.fetchReasons()
    this.applyTicketsFilter()
    this.fetchAttendants()
    await this.fetchSettings()
    for (const setting of this.allSettings) {
      this.phones.push(setting)
    }
    if (this.getIsSupervisorAllDepartments) {
      await this.fetchDepartments()
      this.userDepartments = this.allDepartments
    } else {
      if (!this.getUserDepartments || typeof this.getUserDepartments === 'string') {
        await this.fetchUserDetails(this.getToken)
      }
      this.userDepartments = this.getUserDepartments
    }
  },
  watch: {
    afterHoursFilter(newValue) {
      if (newValue) {
        this.statusFilter = 'FEC'
        this.attendantsFilter = null
        this.departmentsFilter = null
        this.reasonFilter = null
        this.typeFilter = 'USR'
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchTicketsReport',
      'fetchAttendants',
      'fetchContacts',
      'fetchDepartments',
      'fetchReasons',
      'transferTicket',
      'fetchSettings',
      'fetchUserDetails'
    ]),
    getPhone(ticketSetting) {
      if (ticketSetting) {
        let phone = ''
        if (ticketSetting.name) {
          phone = phone + ticketSetting.name + ' - '
        }
        if (ticketSetting.phone) {
          phone = phone + ticketSetting.phone
        }
        return phone
      }
      return ''
    },
    applyTicketsFilter() {
      this.fetchTicketsReport({
        client_name_phone: this.namePhoneFilter,
        ticket_status: this.statusFilter,
        attendants_filter: this.attendantsFilter,
        contacts_filter: this.contactsFilter,
        departments_filter: this.departmentsFilter,
        ticket_reason_id: this.reasonFilter,
        type_filter: this.typeFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter,
        closed_date_from_filter: this.closedDateFromFilter,
        closed_date_to_filter: this.closedDateToFilter,
        ticket_id: this.ticketID,
        tickets_without_department: this.withoutDepartmentFilter,
        phones_filter: this.phoneFilter,
        after_hours_filter: this.afterHoursFilter,
        ticket_origin: this.ticketOriginFilter
      })
    },
    async info(action, ticket) {
      switch (action) {
        case 'details':
          this.selectedTicket = ticket
          break
        case 'transfer':
          this.selectedTicket = ticket
          await this.fetchAttendants()
          break
        case 'reloadTickets':
          this.applyTicketsFilter()
          break
        default:
          break
      }
    },
    statusChange() {
      if (this.statusFilter === 'TOD' || this.statusFilter === 'FEC') {
        this.disabledReasonFilter = false
      } else {
        this.disabledReasonFilter = true
        this.reasonFilter = ''
        this.closedDateFromFilter = null
        this.closedDateToFilter = null
      }
    },
    async exportData() {
      this.isExporting = true
      await this.fetchTicketsReport({
        client_name_phone: this.namePhoneFilter,
        ticket_status: this.statusFilter,
        attendants_filter: this.attendantsFilter,
        contacts_filter: this.contactsFilter,
        departments_filter: this.departmentsFilter,
        ticket_reason_id: this.reasonFilter,
        type_filter: this.typeFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter,
        closed_date_from_filter: this.closedDateFromFilter,
        closed_date_to_filter: this.closedDateToFilter,
        ticket_id: this.ticketID,
        tickets_without_department: this.withoutDepartmentFilter,
        phones_filter: this.phoneFilter,
        after_hours_filter: this.afterHoursFilter,
        ticket_origin: this.ticketOriginFilter
      })
      try {
        const tickets = this.ticketsReport.map(ticket => {
          const contact = ticket.contact ? `${ticket.contact.id} - ${ticket.contact.name}` : ''
          const department = ticket.department ? `${ticket.department.id} - ${ticket.department.name}` : ''
          const closeReason = ticket.ticket_reason ? ticket.ticket_reason.title : ''
          return {
            id: ticket.id,
            ww_phone: this.getPhone(ticket.setting),
            client_name: ticket.client_name,
            contact: contact,
            department: department,
            client_phone: ticket.client_phone,
            type: ticket.is_group ? 'Grupo' : 'Pessoa',
            attendant: ticket.attendant ? ticket.attendant.name : '',
            status: ticket.is_open ? 'Aberto' : 'Fechado',
            created_at: this.formatDateTime(ticket.created_at),
            closed_at: ticket.closed_at ? this.formatDateTime(ticket.closed_at) : '',
            close_reason: closeReason,
            ticket_reason_explanation: ticket.ticket_reason_explanation,
            origin: ticket.origin === 'CLI' ? 'Cliente' : ticket.origin === 'ATE' ? 'Atendente' : 'Integração'
          }
        })

        const sheets = [
          {
            name: 'Tickets',
            headers: [
              { header: 'ID', key: 'id', width: 10 },
              { header: 'Telefone Zeeps', key: 'ww_phone', width: 20 },
              { header: 'Nome no whatsapp', key: 'client_name', width: 20 },
              { header: 'Contato', key: 'contact', width: 20 },
              { header: 'Departamento', key: 'department', width: 20 },
              { header: 'Telefone ', key: 'client_phone', width: 20 }, // tratar para não exibir caso seja um grupo
              { header: 'Tipo', key: 'type', width: 20 },
              { header: 'Origem', key: 'origin', width: 20 },
              { header: 'Atendente', key: 'attendant', width: 20 },
              { header: 'Status', key: 'status', width: 20 },
              { header: 'Data de abertura', key: 'created_at', width: 20 },
              { header: 'Data de fechamento', key: 'closed_at', width: 20 },
              { header: 'Motivo de fechamento', key: 'close_reason', width: 20 },
              { header: 'Descrição de fechamento', key: 'ticket_reason_explanation', width: 20 },
              { header: 'Origem', key: 'origin', width: 20 }
            ],
            data: tickets
          }
        ]

        await exportExcel('Tickets Zeeps', sheets)

        this.isExporting = false
      } catch (error) {
        console.log(error)
        this.isExporting = false
        this.$buefy.toast.open('Não foi possível exportar seu relatório de tickets')
      }
    }
  }
}
</script>

<style scoped>
.multiselect--active {
  z-index: 9999;
}

.multiselect__content-wrapper {
  z-index: 9999 !important;
}
</style>
